import React, { useState } from 'react'
import './Header.scss'
import LinkedinImg from '../Assets/Images/linkedin_white.avif'
import GithubImg from '../Assets/Images/github_white.avif'
import EmailImg from '../Assets/Images/email_white.avif'

const Header = () => {
    const [menuActive, isMenuActive] = useState(false);

    const handleMenu = () =>{
      if (menuActive){
        isMenuActive(false)
      }
      else {
        isMenuActive(true)
      }
  
    }

    return (
        <div className='Header'>
            <div className='Logo-container'>
                <h1>Portfolio</h1>
                <h2>by Michal Zareba</h2>
            </div>
            <div className={`Main-menu ${menuActive ? 'menu-active' : ''}`}>
                <nav>
                    <ul>
                        <li><a href='#AboutMe' onClick={handleMenu}><span>About me</span></a></li>
                        <li><a href='#Portfolio' onClick={handleMenu}>Portfolio</a></li>
                        <li><a href='#MySkills' onClick={handleMenu}>My Skills</a></li>
                        <li><a href='#Contact' onClick={handleMenu}>Contact</a></li>
                    </ul>
                </nav>

            </div>
            <div className='Social-media'>
                <ul>
                    <li><a href='https://www.linkedin.com/in/micha%C5%82-zar%C4%99ba-150a38139/'><img src={LinkedinImg} className='Social-icon' alt="linkedin icon" /><span className="Social-label">LinkedIn</span></a></li>
                    <li><a href='https://github.com/michalzareba0001'><img src={GithubImg} className='Social-icon' alt="Github icon"/>Github</a></li>
                    <li><a href='mailto:michalzareba0001@gmail.com'><img src={EmailImg} className='Social-icon' alt="Email icon" />michalzareba0001@gmail.com</a></li>
                </ul>
            </div>
            <div className='mobile-only'>
        <div className={`hamburger-menu ${menuActive ? 'hamburger-menu-active' : ''}`} onClick={handleMenu}>
          <div className='bar1'></div>
          <div className='bar2'></div>
          <div className='bar3'></div>
        </div>
      </div>

        </div>
    )
}

export default Header