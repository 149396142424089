import React, { useRef, useEffect, useState } from 'react'
import Slider from 'react-slick'
import './Portfolio.scss'
import Slide from './Slide'

import scharmach from '../Assets/Images/portfolio/scharmach.avif'
import marketingv8 from '../Assets/Images/portfolio/marketingv8.avif'
import bialaorlica from '../Assets/Images/portfolio/bialaorlica.avif'
import fitmonica from '../Assets/Images/portfolio/fitmonica.avif'
import hejla from '../Assets/Images/portfolio/hejla.avif'
import hejladeweloper from '../Assets/Images/portfolio/hejladeweloper.avif'
import jedynkapelplin from '../Assets/Images/portfolio/jedynkapelplin.avif'
import mimico from '../Assets/Images/portfolio/mimico.avif'
import kalcargo from '../Assets/Images/portfolio/kalcargo.avif'
import nietylkowlochy from '../Assets/Images/portfolio/nietylkowlochy.avif'
import agrowitan from '../Assets/Images/portfolio/agrowitan.avif'
import mauritiusdiscover from '../Assets/Images/portfolio/mauritiusdiscover.avif'
import virtualmauritius from '../Assets/Images/portfolio/virtualmauritius.avif'
import odzyskajinwestycje from '../Assets/Images/portfolio/odzyskajinwestycje.avif'
import tppp from '../Assets/Images/portfolio/tppp.avif'
import polska2024 from '../Assets/Images/portfolio/polska2024.avif'
import goldslim from '../Assets/Images/portfolio/goldslim.avif'
import dosenunddeckel from '../Assets/Images/portfolio/dosenunddeckel.avif'



const Portfolio = () => {
    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)

    useEffect(() => {
        setNav1(mainSlider.current)
        setNav2(navSlider.current)
    }, [])

    const mainSlider = useRef(null)
    const navSlider = useRef(null)

    const mainSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: nav2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true
                }
            }
        ]
    }

    const navSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: nav1,
        dots: true,
        centerMode: true,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 5000,
    }

    const slides = [
        {
            image: scharmach,
            title: 'scharmach.pl',
            tools: 'Wordpress + HTML + Scss + JS',
            description: 'I handled the repair, redesign, and optimization of the website to enhance its performance. The site features a modern design and is fully responsive, ensuring a seamless experience across all devices. It includes rich animations and additional special effects for a dynamic user experience.',
            link: 'https://scharmach.pl/'
        },
        {
            image: marketingv8,
            title: 'marketingv8.co',
            tools: 'React.js + Scss',
            description: `Professional, modern webpage with a dark-themed layout. The client prioritized fast performance, so I used React.js to ensure quick and efficient page loading. The result is a sleek, high-performing website that meets the client's needs.`,
            link: 'https://marketingv8.co/'
        },
        {
            image: bialaorlica,
            title: 'bialaorlica.pl',
            tools: 'Wordpress + Scss + JS',
            description: 'Elegant, simple, and responsive website designed to reflect a premium brand. The site features a clean design that ensures optimal performance across all devices, perfectly capturing the essence of luxury.',
            link: 'https://bialaorlica.pl/'
        },
        {
            image: mimico,
            title: 'mimico-mirrors.de',
            tools: 'Wordpress + Scss + JS',
            description: 'Created for a German client, features a clear design that effectively presents the brand and encourages user engagement. The website is structured to be inviting and easy to navigate, making it simple for visitors to get in touch.',
            link: 'https://mimico-mirrors.de/'
        },
        {
            image: hejla,
            title: 'hejla.pl',
            tools: 'Wordpress + Scss + JS',
            description: 'Simple, modern website characterized by an abundance of green and natural colors. It offers easy navigation and is fully responsive, ensuring a seamless user experience across all devices.',
            link: 'https://hejla.pl/'
        },
        {
            image: hejladeweloper,
            title: 'hejla-deweloper.pl',
            tools: 'Wordpress + Scss + JS',
            description: 'Features a modern design with engaging animations. Created for a developer client, the website showcases apartment listings in an attractive and dynamic way, effectively presenting the sales offerings.',
            link: 'https://hejla-deweloper.pl/'
        },
        {
            image: fitmonica,
            title: 'fitmonica.co.uk',
            tools: 'Wordpress + Woocommerce + Scss + JS',
            description: 'Website designed for a personal trainer, featuring options for selling training sessions and dietary supplements. Utilizing WooCommerce keeps operating costs low while providing robust e-commerce capabilities. The site creatively showcases client activity on Instagram, enhancing engagement and visibility.',
            link: 'https://fitmonica.co.uk/'
        },
        {
            image: jedynkapelplin,
            title: 'jedynkapelplin.pl',
            tools: 'Wordpress + Scss + JS',
            description: 'This website was created for an elementary school, featuring accessibility enhancements for disabled individuals. Built on WordPress, it allows for easy content management and blog updates. The site boasts a modern and straightforward design, catering to both functionality and aesthetics.',
            link: 'https://jedynkapelplin.pl/'
        },
        {
            image: kalcargo,
            title: 'kalcargo.pl',
            tools: 'Wordpress + Scss + JS',
            description: `An online business card for a transportation company, designed to encourage contact. The website is fully responsive and uses colors that align with the client's logo, ensuring brand consistency and a polished appearance.`,
            link: 'https://kalcargo.pl/'
        },
        {
            image: nietylkowlochy,
            title: 'sklep.nietylkowlochy.pl',
            tools: 'Wordpress + Woocommerce + Scss + JS',
            description: 'An online store specializing in olive oil, coffee, and other natural products. The color scheme harmonizes with olive tones, reflecting the essence of the products. The client also maintains a blog about travels to Italy and beyond, seamlessly integrated with the website.',
            link: 'https://sklep.nietylkowlochy.pl/'
        },
        {
            image: agrowitan,
            title: 'agrowitan.pl',
            tools: 'Wordpress + Woocommerce + Scss + JS',
            description: 'This website was created for a producer of natural fertilizers, featuring an additional online sales channel. The client prioritized simplicity, ensuring a straightforward user experience. The website is content-rich, serving as a knowledge base for farmers and gardeners alike.',
            link: 'https://agrowitan.pl/'
        },
        {
            image: mauritiusdiscover,
            title: 'mauritiusdiscover.com',
            tools: 'Wordpress + Scss + JS',
            description: `MauritiusDiscover is a website showcasing tours around Mauritius and hotel presentations in this tropical paradise. It features stunning visuals, capturing the essence of a tropical paradise with beautiful imagery. The website is designed to be accessible in multiple language versions, meeting the client's international audience needs.`,
            link: 'https://mauritiusdiscover.com/'
        },
        {
            image: virtualmauritius,
            title: 'virtual3dmauritius.mu',
            tools: 'Wordpress + Scss + JS',
            description: 'Website designed for a photographer, showcasing an innovative product: virtual tours. The site is available in English and French, ensuring accessibility to a broader audience. It is fast and responsive, providing a seamless user experience across all devices.',
            link: 'https://virtual3dmauritius.mu/'
        },
        {
            image: odzyskajinwestycje,
            title: 'odzyskajinwestycje.pl',
            tools: 'HTML + Scss + JS',
            description: 'OdzyskajInwestycje.pl is a simple landing page created for NexusGroup, featuring a lightweight and fast design using clean HTML, CSS, and vanilla JavaScript. The modern contact form is designed to attract and convert clients efficiently.',
            link: 'https://odzyskajinwestycje.pl/'
        }, 
        {
            image: tppp,
            title: 'TPPP - landing page',
            tools: 'HTML + Scss + JS',
            description: 'TPPP is a landing page for a telephone counseling service for smokers, featuring bold and eye-catching colors and illustrations. It encourages contact via phone, emphasizing quick loading times and using .webp images with clean HTML, CSS, and JavaScript.',
            link: 'https://jakrzucicpalenie.pl/telefoniczna-poradnia-pomocy-palacym/'
        },
        {
            image: polska2024,
            title: 'Polska2024',
            tools: 'React Native + Expo',
            description: 'This project marks my first venture into Android app development. The game challenges players to solve puzzles inspired by real-world political events, offering an engaging and thought-provoking experience.',
            link: 'https://play.google.com/store/apps/details?id=com.michalz0001.Polska2024'
        },
        {
            image: goldslim,
            title: 'goldslim.pl',
            tools: 'Wordpress + Woocommerce + Scss + JS',
            description: 'A fast-loading e-commerce website designed for a single product in the supplements industry. The site features a clean and modern layout, with integrated video content to enhance the user experience and product presentation.',
            link: 'https://goldslim.pl/'
        },
        {
            image: dosenunddeckel,
            title: 'dosenunddeckel.de',
            tools: 'Wordpress + Woocommerce + Scss + JS',
            description: 'A modern e-commerce store designed for the German market, offering a simple and seamless ordering process. Fully optimized for mobile devices, ensuring an excellent shopping experience on any screen size.',
            link: 'https://dosenunddeckel.de/'
        }

        
    ]

    return (
        <div className='Portfolio' id='Portfolio'>
            <h2 className='title'>Portfolio</h2>
            <Slider
                {...mainSettings}
                ref={mainSlider}
                className='slider-for'
            >
                {slides.map((slideContent, index) => (
                    <Slide key={index} content={slideContent} />
                ))}
            </Slider>
            <Slider
                {...navSettings}
                ref={navSlider}
                className='slider-nav'
            >
                {slides.map((slideContent, index) => (
                    <div key={index} className='NavSlide'>
                        <img src={slideContent.image} alt={slideContent.title} />
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default Portfolio
